@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	h1 {
		@apply text-3xl font-bold;
	}
	h2 {
		@apply text-2xl font-bold;
	}
	h3 {
		@apply text-xl font-bold;
	}
	h4 {
		@apply text-lg font-bold;
	}
	h5 {
		@apply text-base;
	}
	a {
		@apply text-blue-600 underline;
	}
	p {
		@apply font-light;
	}
}

@layer utilities {
	.link {
		@apply text-[#0C21C1] no-underline hover:underline font-medium;
	}

	.inputField {
		@apply border-b-[1px] border-b-primary-grey font-medium text-[#000842] outline-0 pr-2 py-1 placeholder-[#000842]
		transition-all focus:border-b-primary;
	}

	.primaryButton {
		@apply py-2 px-4 hover:bg-primary-hover transition-all bg-primary text-white;
	}
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
	overflow-x: hidden !important;
	font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans",
		"Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	min-height: 100vh;
	min-width: 100vw;
	background: rgb(197, 196, 213);
	background: linear-gradient(90deg, rgba(197, 196, 213, 1) 0%, rgba(207, 207, 233, 1) 35%, rgba(0, 212, 255, 1) 100%);
}

.blk-videos {
	padding: 10px;
	border-radius: 10px;
	margin-bottom: 10px;
	overflow: hidden;
	background: #fff;
	box-shadow: 10px 10px 5px -3px rgba(0, 0, 0, 0.2);
	-webkit-box-shadow: 10px 10px 5px -3px rgba(0, 0, 0, 0.2);
	-moz-box-shadow: 10px 10px 5px -3px rgba(0, 0, 0, 0.2);
}

.blk-videos .blk-title {
	color: #9f59e1;
	margin-bottom: 5px;
	font-size: 24px;
	font-weight: bold;
}

.blk-videos .blk-icontent iframe {
	width: 100% !important;
}
.blk-videos .blk-icontent.expanded iframe {
	max-height: 70vh !important;
}
.blk-videos .blk-icontent.expanded > div {
	padding: 10 !important;
}
